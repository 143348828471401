import React from "react";
import "../Footer/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  return (
    <div className="footerMain">
      <div className="KathmanduImg">
        <img src="/Images/Nepal.png" alt="" />
      </div>
      <div className="footer">
        <div className="footerLogo">
          <img src="/images/LogoWhite.png" alt="" />
        </div>
        <div className="footerLocation">
          <div className="footerLocationSub address">
            <FontAwesomeIcon
              icon={faLocationDot}
              size="2x"
              className="FontIcon"
              style={{ color: "#f2d6b1" }}
            />
            <a href="https://maps.app.goo.gl/a6dFscfvBzgYSfUa9">
              624 Huron St, London, ON
            </a>
          </div>
          <div className="footerLocationSub phoneNumber">
            <FontAwesomeIcon icon={faPhone} size="2x" className="FontIcon" />
            <a href="tel:+15488660360">+1 (548) 866 0360</a>
          </div>
          <div className="footerLocationSub email">
            <FontAwesomeIcon icon={faEnvelope} size="2x" className="FontIcon" />
            <a href="mailto:mustangthakali624@gmail.com">
              mustangthakali624@gmail.com
            </a>
          </div>
        </div>
        <div className="footerIcon">
          <a href="https://www.facebook.com/profile.php?id=61566831292502">
            <FontAwesomeIcon icon={faFacebook} size="2x" className="FontIcon" />
          </a>
          <a href="https://www.instagram.com/mustang__thakali/">
            <FontAwesomeIcon
              icon={faInstagram}
              size="2x"
              className="FontIcon"
            />
          </a>
          <a href="https://www.tiktok.com/@mustangthakali">
            <FontAwesomeIcon icon={faTiktok} size="2x" className="FontIcon" />
          </a>
        </div>
        <div className="footerBottom">
          <p>Enjoy the taste of the Himalayas</p>
        </div>
      </div>
    </div>
  );
}
