import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import NavBar from './components/NavBar/NavBar.jsx';
import Home from './components/Home/Home.jsx';
import Menu from './components/Menu/Menu.jsx';
import Footer from './components/Footer/Footer.jsx';
import PopularDishes from './components/Popular/PopularDishes.jsx';


function App() {
  return (
    <Router>
      <div className="App">
        {/* Shared NavBar */}
        <NavBar />
        
        {/* Routes for changing content */}
        <Routes>
          <Route path="/" element={
            <>
              <Home />
              <PopularDishes />
            </>
          } />
          <Route path="/menu" element={<Menu />} />
        </Routes>
        
        {/* Shared Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
