// src/components/Menu.js
import React from "react";
import "../Menu/Menu.css";
import { Carousel } from "react-bootstrap";
import MenuCard from "./MenuCard/MenuCard";
import menuData from "../../Data/MenuData";

const Menu = () => {
  return (
    <div className="Menu container">
      <h1 className="text-center">Menu</h1>
      <ul className="nav nav-tabs" id="menuTabs" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="veg-tab"
            data-bs-toggle="tab"
            data-bs-target="#veg"
            type="button"
            role="tab"
            aria-controls="veg"
            aria-selected="true"
          >
            <h2>Veg Appetizer</h2>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="nonveg-tab"
            data-bs-toggle="tab"
            data-bs-target="#nonveg"
            type="button"
            role="tab"
            aria-controls="nonveg"
            aria-selected="false"
          >
            <h2>Non-Veg Appetizer</h2>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="momo-tab"
            data-bs-toggle="tab"
            data-bs-target="#momo"
            type="button"
            role="tab"
            aria-controls="momo"
            aria-selected="false"
          >
            <h2>Momo</h2>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="thakaliKhana-tab"
            data-bs-toggle="tab"
            data-bs-target="#thakaliKhana"
            type="button"
            role="tab"
            aria-controls="thakaliKhana"
            aria-selected="false"
          >
            <h2>Thakali Khana</h2>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="khajaSet-tab"
            data-bs-toggle="tab"
            data-bs-target="#khajaSet"
            type="button"
            role="tab"
            aria-controls="khajaSet"
            aria-selected="false"
          >
            <h2>Khaja Set</h2>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="noodles-tab"
            data-bs-toggle="tab"
            data-bs-target="#noodles"
            type="button"
            role="tab"
            aria-controls="noodles"
            aria-selected="false"
          >
            <h2>Noodles</h2>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="friedRice-tab"
            data-bs-toggle="tab"
            data-bs-target="#friedRice"
            type="button"
            role="tab"
            aria-controls="friedRice"
            aria-selected="false"
          >
            <h2>Fried Rice</h2>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="soup-tab"
            data-bs-toggle="tab"
            data-bs-target="#soup"
            type="button"
            role="tab"
            aria-controls="soup"
            aria-selected="false"
          >
            <h2>Soup</h2>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="desserts-tab"
            data-bs-toggle="tab"
            data-bs-target="#desserts"
            type="button"
            role="tab"
            aria-controls="desserts"
            aria-selected="false"
          >
            <h2>Desserts</h2>
          </button>
        </li>
      </ul>
      <div className="tab-content" id="menuTabContent">
        {/* Veg Appetizer Tab */}
        <div
          className="tab-pane fade show active menuGroup"
          id="veg"
          role="tabpanel"
          aria-labelledby="veg-tab"
        >
          {menuData.vegAppetizer.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>

        {/* Non-Veg Appetizer Tab */}
        <div
          className="tab-pane fade"
          id="nonveg"
          role="tabpanel"
          aria-labelledby="nonveg-tab"
        >
          {menuData.nonVegAppetizer.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>
        {/* Thakali Khana Set Tab */}
        <div
          className="tab-pane fade"
          id="thakaliKhana"
          role="tabpanel"
          aria-labelledby="thakaliKhana-tab"
        >
          {menuData.thakaliKhanaSet.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>
        {/* Momo Tab */}
        <div
          className="tab-pane fade"
          id="momo"
          role="tabpanel"
          aria-labelledby="momo-tab"
        >
          {menuData.momo.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>
        {/* Khaja Set Tab */}
        <div
          className="tab-pane fade"
          id="khajaSet"
          role="tabpanel"
          aria-labelledby="khajaSet-tab"
        >
          {menuData.khajaSet.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>
        {/* Noodle Tab */}
        <div
          className="tab-pane fade"
          id="noodles"
          role="tabpanel"
          aria-labelledby="noodle-tab"
        >
          {menuData.noodles.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>
        {/* Fried Rice Tab */}
        <div
          className="tab-pane fade"
          id="friedRice"
          role="tabpanel"
          aria-labelledby="friedRice-tab"
        >
          {menuData.friedRice.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>
        {/* Soup Tab */}
        <div
          className="tab-pane fade"
          id="soup"
          role="tabpanel"
          aria-labelledby="soup-tab"
        >
          {menuData.soup.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>
        {/* Desserts Set Tab */}
        <div
          className="tab-pane fade"
          id="desserts"
          role="tabpanel"
          aria-labelledby="desserts-tab"
        >
          {menuData.desserts.map((item) => (
            <MenuCard
              key={item.id}
              name={item.name}
              description={item.description}
              price={item.price}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Menu;
