import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../NavBar/NavBar.css";

export default function NavBar() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const closeNavbar = () => {
    setIsCollapsed(true);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <Link
          className="navbar-brand logoNavBar"
          to="/"
          aria-current="page"
          onClick={closeNavbar}
        >
          <img
            src="/images/Logo.png"
            alt="Logo"
            className="d-inline-block align-text-top"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarNav"
          aria-expanded={!isCollapsed}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isCollapsed ? "" : "show"}`}
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link active"
                to="/"
                aria-current="page"
                onClick={closeNavbar}
              >
                <h2>Home</h2>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link active"
                to="/menu"
                aria-current="page"
                onClick={closeNavbar}
              >
                <h2>Menu</h2>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
