const menuData = {
  vegAppetizer: [
    {
      id: 1,
      name: "Green Salad",
      description: "A fresh blend of crunchy lettuce, cucumbers, and tomatoes, served with a tangy dressing",
      price: "$09.99",
    },
    {
      id: 2,
      name: "Chips Chilly",
      description: "Crispy potato chips tossed in a spicy chili sauce with onions, peppers, and a hint of tangy seasoning",
      price: "$11.99",
    },
    {
      id: 3,
      name: "French Fries",
      description: "Golden, crispy fries seasoned with a pinch of salt",
      price: "$10.99",
    },

    {
      id: 3,
      name: "Mustang Aaloo",
      description: "Perfectly cooked potatoes tossed in traditional Nepali spices",
      price: "$10.99",
    }
  ],
  nonVegAppetizer: [
    {
      id: 1,
      name: "Chicken Choila",
      description: "Grilled chicken marinated in spices, garlic, and mustard oil, served with chili",
      price: "$16.99",
    },
    {
      id: 2,
      name: "Buff Choila",
      description: "Grilled buff marinated in spices, garlic, and mustard oil, served with chili",
      price: "$17.99",
    },
    {
      id: 3,
      name: "Buff Sukuti",
      description: "Smoky dried buffalo meat seasoned with traditional Nepali spices",
      price: "$17.99",
    },
    {
      id: 4,
      name: "Sausage",
      description: "Crispy fried sausage served with a side of tangy, spicy achar",
      price: "$08.99",
    },
    {
      id: 5,
      name: "Chicken Lollipop",
      description: "Light and airy popcorn drizzled with truffle oil and sprinkled with freshly grated Parmesan",
      price: "$15.99",
    },
    {
      id: 6,
      name: "Buff Chilly",
      description: "Spicy stir-fried buff with onions, peppers, and a tangy chili sauce",
      price: "$17.99",
    },
    {
      id: 7,
      name: "Pork Chilly",
      description: "Spicy stir-fried pork with onions, peppers, and a tangy chili sauce",
      price: "$15.99",
    },
  ],
  thakaliKhanaSet: [
    {
      name: "Veg Thakali Khana Set",
      description:
        "A traditional Nepali meal with rice, daal, vegetable curry, papad, achar, gundruk (fermented greens), saag (mustard greens), dahi (curd), and ghee",
      price: "$19.99",
    },
    {
      name: "Local Chicken Thakali Khana Set",
      description:
        "A traditional Nepali meal with rice, daal, chicken curry, papad, achar, gundruk (fermented greens), saag (mustard greens), dahi (curd), and ghee",
      price: "$20.99",
    },
    {
      name: "Boiler Chicken Thakali Khana Set",
      description:
        "A traditional Nepali meal with rice, daal, chicken curry, papad, achar, gundruk (fermented greens), saag (mustard greens), dahi (curd), and ghee",
      price: "$19.99",
    },
    {
      name: "Mutton Khana Set",
      description:
        "A traditional Nepali meal with rice, daal, mutton curry, papad, achar, gundruk (fermented greens), saag (mustard greens), dahi (curd), and ghee",
      price: "$20.99",
    },
    {
      name: "Fish Thakali Khana Set",
      description:
        "A traditional Nepali meal with rice, daal, fish curry, papad, achar, gundruk (fermented greens), saag (mustard greens), dahi (curd), and ghee",
      price: "$20.99",
    },
    {
      name: "Pork Thakali Khana Set",
      description:
        "A traditional Nepali meal with rice, daal, pork curry, papad, achar, gundruk (fermented greens), saag (mustard greens), dahi (curd), and ghee",
      price: "$19.99",
    },
  ],
  momo: [
    {
      name: "Veg Steamed Momo",
      description:
        "Soft dumplings filled with spiced veggies, served with tangy achhar",
      price: "$12.99",
    },
    {
      name: "Chicken Steamed Momo",
      description:
        "Soft dumplings filled with spiced chicken, served with tangy achhar",
      price: "$14.99",
    },
    {
      name: "Buff Steamed Momo",
      description:
        "Soft dumplings filled with spiced buff, served with tangy achhar",
      price: "$16.99",
    },
    {
      name: "Pork Steamed Momo",
      description:
        "Soft dumplings filled with spiced pork, served with tangy achhar",
      price: "$14.99",
    },
    {
      name: "Veg Chilly Momo",
      description:
        "Crispy dumplings stir-fried in a spicy chili sauce with peppers and onions",
      price: "$13.99",
    },
    {
      name: "Chicken Chilly Momo",
      description:
        "Crispy dumplings stir-fried in a spicy chili sauce with peppers and onions",
      price: "$15.99",
    },
    {
      name: "Buff Chilly Momo",
      description:
        "Crispy dumplings stir-fried in a spicy chili sauce with peppers and onions",
      price: "$17.99",
    },
    {
      name: "Pork Chilly Momo",
      description:
        "Crispy dumplings stir-fried in a spicy chili sauce with peppers and onions",
      price: "$15.99",
    },
    {
      name: "Veg Fried Momo",
      description:
        "Crispy golden dumplings stuffed with spiced meat or veggies, paired with zesty achhar",
      price: "$12.99",
    },
    {
      name: "Chicken Fried Momo",
      description:
        "Crispy golden dumplings stuffed with spiced meat or veggies, paired with zesty achhar",
      price: "$14.99",
    },
    {
      name: "Buff Fried Momo",
      description:
        "Crispy golden dumplings stuffed with spiced meat or veggies, paired with zesty achhar",
      price: "$16.99",
    },
    {
      name: "Pork Fried Momo",
      description:
        "Crispy golden dumplings stuffed with spiced meat or veggies, paired with zesty achhar",
      price: "$14.99",
    },
    {
      name: "Mustang Veg Jhol Momo",
      description:
        "Steamed veg dumplings served in a flavorful, tangy, and spicy soup",
      price: "$13.99",
    },
    {
      name: "Mustang Chicken Jhol Momo",
      description:
        "Steamed chicken dumplings served in a flavorful, tangy, and spicy soup",
      price: "$15.99",
    },
    {
      name: "Mustang Buff Jhol Momo",
      description:
        "Steamed buff dumplings served in a flavorful, tangy, and spicy soup",
      price: "$17.99",
    },
    {
      name: "Mustang Pork Jhol Momo",
      description:
        "Steamed pork dumplings served in a flavorful, tangy, and spicy soup",
      price: "$15.99",
    },
  ],
  khajaSet: [
    {
      name: "Veg Khaja Set",
      description:
        "Bitten rice served with pickle, bhatmas, choila, alu tama, and salad",
      price: "$15.99",
    },
    {
      name: "Chicken Khaja Set",
      description:
        "Bitten rice served with pickle, bhatmas, choila, alu tama, and salad",
      price: "$16.99",
    },
    {
      name: "Buff Khaja Set",
      description:
        "Bitten rice served with pickle, bhatmas, choila, alu tama, and salad",
      price: "$17.99",
    },
    {
      name: "Special Khaja Set",
      description:
        "Bitten rice served with pickle, bhatmas, choila, alu tama, and salad",
      price: "$19.99",
    },
  ],
  noodles: [
    {
      name: "Veg Noodles (Chowmein)",
      description:
        "Stir-fried noodles with a mix of vegetables, seasoned with a delicious sauce",
      price: "$12.99",
    },
    {
      name: "Chicken Noodles (Chowmein)",
      description:
        "Stir-fried noodles with a mix of tender chicken and vegetables, seasoned with a delicious sauce",
      price: "$14.99",
    },
    {
      name: "Buff Noodles (Chowmein)",
      description:
        "Stir-fried noodles with a mix of tender buff and vegetables, seasoned with a delicious sauce",
      price: "$15.99",
    },
    {
      name: "Pork Noodles (Chowmein)",
      description:
        "Stir-fried noodles with a mix of tender pork and vegetables, seasoned with a delicious sauce",
      price: "$14.99",
    },
    {
      name: "Egg Noodles (Chowmein)",
      description:
        "Stir-fried noodles with a mix of eggs and vegetables, seasoned with a delicious sauce",
      price: "$14.99",
    },
    {
      name: "Mixed Noodles (Chowmein)",
      description:
        "Stir-fried noodles with a mix of tender meat and vegetables, seasoned with a delicious sauce",
      price: "$17.99",
    },
    {
      name: "Chicken Keema Noodles",
      description:
        "Thick noodles with spiced minced chicken, bok choy, soy sauce, and chili",
      price: "$14.99",
    },
    {
      name: "Buff Keema Noodles",
      description:
        "Thick noodles with spiced minced buff, bok choy, soy sauce, and chili",
      price: "$16.99",
    },
    {
      name: "Pork Keema Noodles",
      description:
        "Thick noodles with spiced minced pork, bok choy, soy sauce, and chili",
      price: "$14.99",
    },
    {
      name: "Veg Thukpa",
      description: "Noodles in a savory broth with vegetables, and spices",
      price: "$12.99",
    },
    {
      name: "Chicken Thukpa",
      description:
        "Noodles in a savory broth with chicken, vegetables, and spices",
      price: "$15.99",
    },
    {
      name: "Buff Thukpa",
      description: "Noodles in a savory broth with buff, vegetables, and spices",
      price: "$15.99",
    },
    {
      name: "Pork Thukpa",
      description: "Noodles in a savory broth with pork, vegetables, and spices",
      price: "$15.99",
    },
    {
      name: "Egg Thukpa",
      description: "Noodles in a savory broth with eggs, vegetables, and spices",
      price: "$15.99",
    },
    {
      name: "Mixed Thukpa",
      description: "Noodles in a savory broth with meat, vegetables, and spices",
      price: "$16.99",
    },
  ],
  friedRice: [
    {
      name: "Veg Fried Rice",
      description: "Stir-fried rice with vegetables, seasoned with Nepali spices",
      price: "$12.99",
    },
    {
      name: "Chicken Fried Rice",
      description:
        "Stir-fried rice with tender chicken, vegetables, seasoned with Nepali spices",
      price: "$14.99",
    },
    {
      name: "Buff Fried Rice",
      description:
        "Stir-fried rice with tender buff, vegetables, seasoned with Nepali spices",
      price: "$15.99",
    },
    {
      name: "Pork Fried Rice",
      description:
        "Stir-fried rice with tender pork, vegetables, seasoned with Nepali spices",
      price: "$14.99",
    },
    {
      name: "Egg Fried Rice",
      description:
        "Stir-fried rice with egg, vegetables, seasoned with Nepali spices",
      price: "$14.99",
    },
    {
      name: "Mixed Fried Rice",
      description:
        "Stir-fried rice with tender meat, vegetables, seasoned with Nepali spices",
      price: "$16.99",
    },
  ],
  soup: [
    {
      name: "Veg Hot and Sour Soup",
      description:
        "Spicy and sour soup with vegetables, mushrooms, and tofu, flavored with vinegar and chili",
      price: "$07.99",
    },
    {
      name: "Chicken Hot and Sour Soup",
      description:
        "Spicy and sour soup with vegetables, chicken, mushrooms, and tofu, flavored with vinegar and chili",
      price: "$08.99",
    },
    {
      name: "Veg Manchow Soup",
      description:
        "Spicy, hearty soup with vegetables, and crispy noodles, seasoned with bold spices",
      price: "$07.99",
    },
    {
      name: "Chicken Manchow Soup",
      description:
        "Spicy, hearty soup with vegetables, chicken, and crispy noodles, seasoned with bold spices",
      price: "$08.99",
    },
  ],
  desserts: [
    {
      name: "Lalmohan",
      description:
        "Deep-fried dough balls soaked in a warm sugar syrup, creating a crispy outside and a soft, syrupy inside",
      price: "$05.99",
    },
  ]
};

export default menuData;